<template>
  <router-view />
</template>
<script>
import store from '@/store'
import { mapGetters } from 'vuex'
import { analyseQuery } from '@/util'
import { getToken } from '@/util/auth'

export default {
  computed: {
    ...mapGetters([
      'isLogin'
    ])
  },
  created() {
    var lang = navigator.language || navigator.browserLanguage// 常规浏览器语言和IE浏览器
    var langs = ['zh', 'en']
    this.$i18n.locale = 'en'// 截取lang前2位字符
    // console.log('============>'+lang+'=='+navigator.language+'==='+(navigator.language || navigator.browserLanguage).toLowerCase())
    if (langs.indexOf(lang.substr(0, 2)) > -1) {
      this.$i18n.locale = lang.substr(0, 2)// 截取lang前2位字符
    }
    /* if (langs.includes(localStorage.getItem('lang'))) {
       this.$i18n.locale = localStorage.getItem('lang')
     }*/
    localStorage.setItem('lang', this.$i18n.locale)
    const query = analyseQuery(window.location.href)
    console.log('app url:', window.location.href)
    console.log('app token:', query.token)
    // token 登录 当检测到url 存在token 且 没有用户信息时
    if (query.token) { //  && !this.isLogin
      const split = query.token.split('#/');
      var tokenStr = query.token;
      if (split.length > 1) tokenStr = split[0];
      var decodedToken = decodeURIComponent(tokenStr);
      console.log('decodedToken:' + decodedToken);
      store.dispatch('user/setToken', decodedToken)
    }
    store.dispatch('user/getAppInfo')

    // this.goHistory()
  },

  mounted() {
    if (!this.isLogin && getToken()) {
      store.dispatch('user/getInfo').then(res => {
        console.log('userInfo:', res)
      }).catch((e) => {
        console.error('get user info failed:', e)
        // this.$confirm('login error,please relogin', '提示', {
        //   confirmButtonText: this.$t('index.confirm'),
        //   cancelButtonText: this.$t('index.cancel'),
        //   type: 'warning'
        // }).then(() => {
        //   this.$router.push('/login')
        // })
        // this.$confirm('登录信息失效，请重新登录')
      })
    }
  },
  methods: {
    goHistory() {
      let hisstr = localStorage.getItem('currentPath')
      if (hisstr) {
        const p = JSON.parse(hisstr)
        if (p && Object.keys(p).length) {
          // 1分钟之内 回到上次浏览记录
          if (p.time > (new Date().getTime() - 1000 * 60 * 1) && p.path != '') {
            this.$router.push(p.path)
          } else {
            localStorage.setItem('currentPath', JSON.stringify({}))
          }
        }
      }
    }
  }

}
</script>>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  width: 100%;
  height: 100%;
  position: absolute;
  // background: #111111;
}

* {
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
}

body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
}
</style>
